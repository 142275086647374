import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Link from 'next/link';
import { Box, Image, gridTheme } from 'mdlkit';
import Nav from './Nav';
import { HOME_LINK } from '../../../../constants/home';
import { useCustomizable } from '../../../app/CustomizableProvider';

export const HEADER_HEIGHT = [58, 58];

const StyledHeader = styled(Box)<{ dark?: boolean }>`
  position: relative;
  height: ${HEADER_HEIGHT[0]}px;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  ${({ dark, theme }) =>
    dark &&
    css`
      background-color: ${rgba(theme.colors.lightGray, 0.8)};
    `}

  ${({ theme }) => theme.mediaQueries.sm} {
    height: ${HEADER_HEIGHT[1]}px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: ${gridTheme.container.padding}px;
    padding-right: ${gridTheme.container.padding}px;
  }
`;

const StyledImage = styled(Image)`
  height: 45px;
  width: auto;
  float: none;
  display: inline;
  margin: -8px auto 5px 8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    float: left;
  }
`;

export const CenteredWithMixWidthWrapper = styled.div`
  margin: 0 auto;
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: ${gridTheme.container.maxWidth.lg}px;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    max-width: ${gridTheme.container.maxWidth.xl}px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    max-width: ${gridTheme.container.maxWidth.xxl}px;
  }
`;

interface HeaderProps {
  dark?: boolean;
  showNav?: boolean;
  fromPhysicianRecruitment?: boolean;
  internalBrandingLogo?: any;
}

const Header: FunctionComponent<HeaderProps> = ({
  showNav,
  dark,
  fromPhysicianRecruitment = false,
  internalBrandingLogo,
  ...rest
}: HeaderProps) => {
  const { isCustomizable } = useCustomizable();
  return (
    <StyledHeader as="header" p="sm" dark={dark} {...rest}>
      <CenteredWithMixWidthWrapper>
        {isCustomizable ? (
          <StyledImage
            src={internalBrandingLogo?.logo}
            alt={internalBrandingLogo?.alt}
          />
        ) : (
          <Link href={HOME_LINK}>
            <StyledImage
              src={internalBrandingLogo?.logo}
              alt={internalBrandingLogo?.alt}
            />
          </Link>
        )}
        {showNav && <Nav fromPhysicianRecruitment={fromPhysicianRecruitment} />}
      </CenteredWithMixWidthWrapper>
    </StyledHeader>
  );
};

export default Header;
